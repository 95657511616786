
import { defineComponent, PropType } from 'vue';
import { RawLocation } from 'vue-router';
import ProfileDeadlineChip from '@/components/profile/ProfileDeadlineChip/ProfileDeadlineChip.vue';
import { DateTimeISO8601, UUID } from '@/domains/common';
import AtomCoverPlaceholder from '@/components/ui/AtomCoverPlaceholder/AtomCoverPlaceholder.vue';
import { IAtom } from '@/domains/atom';
import { useImageLoader } from '@/composables';

export default defineComponent({
  name: 'PlanEducationItem',

  components: {
    ProfileDeadlineChip,
    AtomCoverPlaceholder,
  },

  props: {
    assignmentUserId: {
      type: String as PropType<UUID>,
      default: '',
    },
    deadline: {
      type: String as PropType<DateTimeISO8601>,
      default: '',
    },
    title: {
      type: String as PropType<string>,
      default: '',
    },
    imgSrc: {
      type: String as PropType<string>,
      default: '',
    },
    progress: {
      type: Number as PropType<number>,
      default: 0,
    },
    to: {
      type: Object as PropType<RawLocation>,
      default: null,
    },
    showLink: {
      type: Boolean,
      default: true,
    },
    playerSessionId: {
      type: String,
      required: true,
    },
    atomType: {
      type: String as PropType<IAtom['atomName']>,
      required: true,
    },
  },

  setup() {
    const {
      isImageLoading,
      onLoadStartHandler,
      onLoadErrorHandler,
    } = useImageLoader();

    return {
      isImageLoading,
      onLoadStartHandler,
      onLoadErrorHandler,
    };
  },

  computed: {
    progressDecorated(): string {
      return `${Math.floor(this.progress)}%`;
    },

    color(): string {
      return this.progress === 100 ? 'tt-light-green vibrant' : 'tt-light-blue vibrant';
    },

    isDisabled(): boolean {
      return false;
    },
  },
});
