
import Vue, { PropType } from 'vue';
import Sidebar from '@/components/ui/Sidebar/Sidebar.vue';
import TeamProgress from '@/components/analytics/TeamProgress.vue';
import ExpiringEmployeeStudyNotifications from '@/components/analytics/ExpiringEmployeeStudyNotifications/ExpiringEmployeeStudyNotifications.vue';
import { IEmployeeNotification } from '@/domains/employee';
import { ITeamProgress } from '@/domains/assignment';

export default Vue.extend({
  name: 'EmployeesProgressSidebar',
  components: {
    Sidebar,
    TeamProgress,
    ExpiringEmployeeStudyNotifications,
  },
  props: {
    teamProgress: {
      type: Object as PropType<ITeamProgress>,
      default: () => ({ progress: 0, progressDiff: 0 } as ITeamProgress),
    },
    employeeNotifications: {
      type: Array as PropType<IEmployeeNotification[]>,
      default: () => [] as IEmployeeNotification[],
    },
  },
});
