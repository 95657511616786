import { render, staticRenderFns } from "./DeadlineCard.vue?vue&type=template&id=73b69df5&scoped=true&"
import script from "./DeadlineCard.vue?vue&type=script&lang=ts&"
export * from "./DeadlineCard.vue?vue&type=script&lang=ts&"
import style0 from "./DeadlineCard.vue?vue&type=style&index=0&id=73b69df5&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73b69df5",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VCard,VImg})
