import { render, staticRenderFns } from "./PlanEducationItem.vue?vue&type=template&id=05966158&scoped=true&"
import script from "./PlanEducationItem.vue?vue&type=script&lang=ts&"
export * from "./PlanEducationItem.vue?vue&type=script&lang=ts&"
import style0 from "./PlanEducationItem.vue?vue&type=style&index=0&id=05966158&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05966158",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VIcon,VImg,VProgressLinear})
