
import Vue, { VueConstructor } from 'vue';
import { getImageUrl } from '@/helpers/gogha/getImageUrl';
import getInitials from '@/helpers/getInitials';
import getFullname from '@/helpers/getFullname';
import UserDetailView from '@/components/user-detail/UserDetailView/UserDetailView.vue';
import UserDetailBigView from '@/components/user-detail/UserDetailView/UserDetailBigView.vue';

export default Vue.extend({
  name: 'UserDetailContainer',
  components: { UserDetailView, UserDetailBigView },
  props: {
    userId: {
      type: String,
      required: false,
      default: '',
    },
    firstname: {
      type: String,
      required: true,
    },
    lastname: {
      type: String,
      required: true,
    },
    middlename: {
      type: String,
      required: false,
      default: '',
    },
    post: {
      type: String,
      required: false,
      default: '',
    },
    size: {
      type: String,
      default: 'large',
    },
  },
  computed: {
    fullname(): string {
      return getFullname({ firstname: this.firstname, lastname: this.lastname });
    },

    initials(): string {
      return getInitials({ firstname: this.firstname, lastname: this.lastname });
    },

    avatarUrl(): string {
      return getImageUrl({
        uuid: this.userId,
        type: 'user',
        name: 'avatar',
        size: '120x120',
      });
    },

    UserDetailComponent(): VueConstructor {
      if (this.size === 'large') return UserDetailBigView;
      return UserDetailView;
    },
  },
});
