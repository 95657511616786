
import {
  computed, defineComponent, onMounted, ref, watch,
} from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { useRoute } from 'vue-router/composables';
import ProfileSidebar from '@/components/profile/ProfileSidebar/ProfileSidebar.vue';
import ProfileAssignmentFilters, {
  AssignmentFilter,
  AtomTypeFilter,
} from '@/views/App/Profile/ProfileAssignmentFilters.vue';
import ProfilePlanEducation from '@/components/profile/ProfilePlanEducation/ProfilePlanEducation.vue';
import TestResultWrapper from '@/components/profile/TestResult/TestResultWrapper.vue';
import { analyticsUserGet } from '@/services/api/lxp-assignment';
import {
  IAnalyticSubordinateUserGetResponseData,
  IAnalyticSubordinateUserTestsResults,
} from '@/services/api/lxp-assignment/types';
import { AssignmentStatus, IAnalyticSubordinateUserAssignments, TestResultType } from '@/domains/assignment';
import UserDetailContainer, { IUserDetailContainerProps } from '@/components/user-detail';
import { BasePage } from '@/components/ui/BasePage';
import { AtomType } from '@/domains/atom';
import { useDi } from '@/plugins';
import { debounce, orderBy } from '@/helpers';
import { getUserId } from '@/helpers/getUserId';
import getFullname from '@/helpers/getFullname';
import { getImageUrl } from '@/helpers/gogha/getImageUrl';
import EmptyBlock from '@/components/ui/EmptyBlock.vue';
import EmptyListImage from '@/assets/img/atoms-empty-filtered-list.png';
import { useI18n } from '@/plugins/vue-i18n';
import { useDayJs } from '@/plugins/dayjs/composables';
import { useFeatureFlag } from '@/plugins/feature-flags';

const userHelpers = createNamespacedHelpers('user');

const atomTypesValues: Set<string> = new Set(Object.values(AtomType));
const assignmentStatusesValues: Set<string> = new Set(Object.values(AssignmentStatus));

export const isAtomType = (value: string): value is AtomType => atomTypesValues.has(value);
export const isAssignmentStatus = (value: string): value is AssignmentStatus => assignmentStatusesValues.has(value);

const toEntry = <T extends AssignmentStatus | AtomType>(value: T) => ([value, 0]);

export default defineComponent({
  name: 'Profile',

  components: {
    ProfileSidebar,
    ProfileAssignmentFilters,
    ProfilePlanEducation,
    TestResultWrapper,
    UserDetailContainer,
    BasePage,
    EmptyBlock,
  },

  inject: ['Names'],

  setup() {
    const di = useDi();
    const route = useRoute();
    const { t } = useI18n();
    const dayjs = useDayJs();

    const isNewAtomProgramAvailable = useFeatureFlag('new_atom_program');
    const isAtomPublicationAvailable = useFeatureFlag('atom_publication');

    const isLoading = ref(false);
    const isInitialLoading = ref(true);
    const assignmentStatuses = ref<AssignmentStatus[]>([]);
    const atomTypes = ref<AtomType[]>([]);
    const allAssignments = ref<IAnalyticSubordinateUserAssignments[]>([]);
    const counters = ref<Record<AssignmentStatus | AtomType, number>>(Object.fromEntries([
      ...Object.values(AtomType).map(toEntry),
      ...Object.values(AssignmentStatus).map(toEntry),
    ]));
    const user = ref<{
      userId: UUID;
      userFirstName: string;
      userLastName: string;
      staffPositionName: string; // Должность
      totalProgress: number; // прогресс по всем программам в процентах
      changePerWeek: number; // изменение прогресса за неделю
      assignments: IAnalyticSubordinateUserAssignments[];
      userTestResults: IAnalyticSubordinateUserTestsResults[];
    }>({
      userId: '',
      userFirstName: '',
      userLastName: '',
      staffPositionName: '',
      totalProgress: 0,
      changePerWeek: 0,
      assignments: [],
      userTestResults: [],
    });

    const userId = computed(() => route.params.employeeId ?? getUserId());

    const filtersValues = ref<(AssignmentStatus | AtomType)[]>([]);

    const fetchUser = async (_userId: string, request: {
      filters: (AssignmentStatus | AtomType)[],
    } = { filters: [] }): Promise<IAnalyticSubordinateUserGetResponseData> => {
      isLoading.value = true;

      if (!userId) throw new ReferenceError('userId should be defined');

      const payload = {
        statusesFilter: request.filters.filter(isAssignmentStatus),
        atomTypeFilter: request.filters.filter(isAtomType),
      };

      try {
        const response = await analyticsUserGet(_userId, payload);

        user.value = response;

        return response;
      } catch (e: unknown) {
        di.notify.error({ content: 'Не удалось получить данные сотрудника' });
        console.error(e);
        throw e;
      } finally {
        isLoading.value = false;
      }
    };

    const assignments = computed<IAnalyticSubordinateUserAssignments[]>(() => {
      return user.value ? user.value.assignments : [];
    });

    const testsResults = computed<IAnalyticSubordinateUserTestsResults[]>(() => {
      return user.value.userTestResults || [];
    });

    const hasAssignments = computed(() => {
      return assignments.value.length > 0;
    });

    const hasTestsResults = computed(() => {
      return testsResults.value.length > 0;
    });

    const isPlaceholderVisible = computed(() => {
      return !hasAssignments && !hasTestsResults;
    });

    const userDeadlineWarnings = computed<IAnalyticSubordinateUserGetResponseData['assignments']>(() => {
      if (!user.value) return [];

      const today = dayjs();

      const deadlined = allAssignments.value
        .filter((i) => (i.finishDate && dayjs(i.finishDate).isSameOrAfter(today, 'day')) && i.progress < 100);

      if (!isAtomPublicationAvailable.value) {
        const deadlinedTracks = deadlined.filter(({ atomType }) => atomType === AtomType.TRACK);

        return orderBy(deadlinedTracks, ['finishDate'], ['asc']);
      }
      return orderBy(deadlined, ['finishDate'], ['asc']);
    });

    const filtersList = computed<(AssignmentFilter | AtomTypeFilter)[]>(() => {
      const list: (AssignmentFilter | AtomTypeFilter)[] = [
        {
          icon: '$exclamationCircle',
          text: 'Назначенные',
          value: AssignmentStatus.ASSIGNED_BY_ADMIN,
          dataTestLabel: 'assignmented',
          iconColor: 'tt-color-deeppurple-300',
          count: counters.value[AssignmentStatus.ASSIGNED_BY_ADMIN],
        },

        {
          icon: '$chevronCircleRight',
          text: 'В процессе',
          value: AssignmentStatus.IN_PROCESS,
          dataTestLabel: 'in-progress',
          iconColor: 'tt-color-blue-300',
          count: counters.value[AssignmentStatus.IN_PROCESS],
        },

        {
          icon: '$star',
          text: 'Самообучение',
          value: AssignmentStatus.SELF_ASSIGNED,
          dataTestLabel: 'favorites',
          iconColor: 'tt-color-amber-300',
          count: counters.value[AssignmentStatus.SELF_ASSIGNED],
        },
      ];

      if (isAtomPublicationAvailable.value) {
        list.push(
          {
            icon: '$questionSquare',
            text: 'Тесты',
            value: [AtomType.MULTILEVELTEST, AtomType.QUIZ],
            dataTestLabel: 'tests',
            iconColor: 'tt-color-purple-300',
            count: counters.value[AtomType.MULTILEVELTEST] + counters.value[AtomType.QUIZ],
          },

          {
            icon: '$play',
            text: 'Занятия',
            value: AtomType.MULTICONTENT,
            dataTestLabel: 'favorites',
            iconColor: 'tt-color-cyan-300',
            count: counters.value[AtomType.MULTICONTENT],
          },

          {
            icon: '$graduationCap',
            text: 'Курсы',
            value: [AtomType.TRACK, AtomType.SCORM],
            dataTestLabel: 'favorites',
            iconColor: 'tt-color-pink-300',
            count: counters.value[AtomType.TRACK] + counters.value[AtomType.SCORM],
          },
        );
      }

      if (isNewAtomProgramAvailable.value) {
        list.push({
          icon: '$list',
          text: 'Программы',
          value: AtomType.PROGRAM,
          dataTestLabel: 'favorites',
          iconColor: 'tt-color-green-300',
          count: counters.value[AtomType.PROGRAM],
        });
      }

      return list;
    });

    watch([filtersValues], debounce(() => {
      fetchUser(userId.value, {
        filters: filtersValues.value,
      }).catch(() => {
        di.notify.error({
          content: 'Не удалось получить назначения, пожалуйста попробуйте позже или обратитесь в поддержку',
        });
      });
    }, 33));

    const init = async () => {
      isInitialLoading.value = true;

      try {
        allAssignments.value = [];

        const response = await fetchUser(userId.value);

        allAssignments.value = response?.assignments;

        counters.value = response.assignments.reduce((acc, assignment: IAnalyticSubordinateUserAssignments) => {
          assignment.statuses.forEach((status) => {
            acc[status] += 1;
          });

          acc[assignment.atomType] += 1;

          return acc;
        }, Object.fromEntries([
          ...Object.values(AtomType).map(toEntry),
          ...Object.values(AssignmentStatus).map(toEntry),
        ]));
      } catch (e: unknown) {
        di.notify.error({
          content: 'Не удалось получить данные сотрудника, пожалуйста попробуйте позже или обратитесь в поддержку',
        });
      } finally {
        isInitialLoading.value = false;
      }
    };

    onMounted(() => {
      init();
    });

    return {
      t,
      isNewAtomProgramAvailable,
      isAtomPublicationAvailable,
      user,
      isInitialLoading,
      isLoading,
      assignmentStatuses,
      atomTypes,
      assignments,
      allAssignments,
      testsResults,
      userDeadlineWarnings,
      hasAssignments,
      isPlaceholderVisible,
      EmptyListImage,
      filtersList,
      filtersValues,
    };
  },

  computed: {
    ...userHelpers.mapGetters({ currentUserId: 'userId' }),

    isMyOwnProfile(): boolean {
      return this.currentUserId === this.userId;
    },

    testResultType(): typeof TestResultType {
      return TestResultType;
    },

    userId(): string {
      return this.$route.params?.employeeId ?? this.currentUserId;
    },

    userAssignments(): IAnalyticSubordinateUserGetResponseData['assignments'] {
      if (!this.user) return [];

      const assignments = this.isAtomPublicationAvailable
        ? this.user.assignments
        : this.user.assignments.filter(({ atomType }) => atomType === AtomType.TRACK);

      const tags = this.assignmentStatuses;

      return tags.length > 0
        ? assignments.filter((a) => a.statuses.some((s) => tags.includes(s)))
        : assignments;
    },

    getUserFullName(): string {
      return getFullname({
        firstname: this.user?.userFirstName ?? '',
        lastname: this.user?.userLastName ?? '',
      });
    },

    userDetailsProps(): IUserDetailContainerProps {
      return {
        userId: this.user?.userId ?? '',
        firstname: this.user?.userFirstName ?? '',
        lastname: this.user?.userLastName ?? '',
        middlename: '',
        post: this.user?.staffPositionName ?? '',
        size: 'large',
      };
    },

    totalProgress(): number | undefined {
      // TODO: Убрать приведение к числу когда пофиксят на бэке
      return this.user?.totalProgress;
    },

    changePerWeek(): number | undefined {
      // TODO: Убрать приведение к числу когда пофиксят на бэке
      return this.user?.changePerWeek;
    },

    /**
     * Отвечает за отображение ссылок на программы в списке назначенных программ.
     * Для руководителя просматривающего страницу сотрудника ссылки нужно скрыть.
     * На данный момент понять в каком режиме отображается страница можно
     * только по параметру в URL
     */
    isChief(): boolean {
      return Boolean(this.$route.params?.employeeId);
    },

    placeholderActionUrl(): string {
      const route = this.$router.resolve({
        name: this.Names.R_APP_LEARNING,
      });

      return route.href;
    },
  },

  methods: {
    courseCover(id: string) {
      return getImageUrl({
        uuid: id,
        type: 'course',
        name: 'cover',
        size: '80x80',
      });
    },
  },
});
