
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'ProgressChangeIndicator',
  props: {
    value: {
      type: Number as PropType<number>,
      default: 0,
    },
  },
  computed: {
    icon() {
      if (this.value > 0) {
        return {
          name: '$arrowCircleUp',
          color: 'tt-light-green vibrant',
        };
      }
      if (this.value < 0) {
        return {
          name: '$arrowCircleDown',
          color: 'tt-light-red',
        };
      }

      return null; // Можно добавить иконку для состояния без изменений, если нарисуют
    },

    progressPercent(): string {
      return this.value < 1 ? `${this.value.toFixed(2)}%` : `${Math.floor(this.value)}%`;
    },
  },
});
