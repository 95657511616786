
import Vue, { PropType } from 'vue';
import { RawLocation } from 'vue-router';
import UserAvatar from '@/components/ui/UserAvatar.vue';
import { SubordinateStatus } from '@/domains/assignment';

interface IUser {
  id: string,
  firstName: string,
  lastName: string,
}

export default Vue.extend({
  name: 'EmployeeProgressCard',
  components: {
    UserAvatar,
  },
  inject: ['Names'],
  props: {
    userId: {
      type: String as PropType<string>,
      default: '',
    },
    userFirstName: {
      type: String as PropType<string>,
      default: '',
    },
    userLastName: {
      type: String as PropType<string>,
      default: '',
    },
    progressValue: {
      type: Number as PropType<number>,
      default: 0,
      validator(v: number) {
        return v >= 0 && v <= 1;
      },
    },
    progressDiff: {
      type: Number as PropType<number>,
      default: 0,
      validator(v: number) {
        return v >= -1 && v <= 1;
      },
    },
    progressStatus: {
      type: String as PropType<SubordinateStatus>,
      required: true,
    },
    isTracksAssigned: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  computed: {
    user(): IUser {
      return {
        id: this.userId,
        firstName: this.userFirstName,
        lastName: this.userLastName,
      };
    },
    showProgressbar(): boolean {
      return [
        SubordinateStatus.PENDING,
        SubordinateStatus.IN_PROCESS,
        SubordinateStatus.COMPLETED,
      ].includes(this.progressStatus);
    },
    employeePage(): RawLocation {
      return {
        name: this.Names.R_APP_MANAGER_ANALYTICS_EMPLOYEE_VIEW,
        params: {
          employeeId: this.user?.id ?? '',
        },
      };
    },
    progressPercent(): string {
      return `${Math.floor(this.progressValue * 100)}%`;
    },

    progressDiffPercent(): string {
      const percent = this.progressDiff * 100;
      const normalizedPercent = percent < 1 ? percent.toFixed(2) : Math.floor(percent);

      return `${normalizedPercent}%`;
    },
  },
});
