
import Vue, { PropType } from 'vue';
import Sidebar from '@/components/ui/Sidebar/Sidebar.vue';
import AssignedLearningProgressCard from '@/components/profile/AssignedLearningProgressCard/AssignedLearningProgressCard.vue';
import DeadlineCard from '@/components/profile/DeadlineCard/DeadlineCard.vue';

import { getTrackCover } from '@/domains/track';
import { IAnalyticSubordinateUserGetResponseData } from '@/services/api/lxp-assignment/types';
import { User } from '@/domains/user';
import { IAnalyticSubordinateUserAssignments } from '@/domains/assignment';
import EmptyAssignmentsListWidget from '../EmptyAssignmentsListWidget.vue';

export default Vue.extend({
  name: 'ProfileSidebar',
  components: {
    Sidebar,
    AssignedLearningProgressCard,
    DeadlineCard,
    EmptyAssignmentsListWidget,
  },
  props: {
    userId: {
      type: String as PropType<User['userId']>,
      required: true,
    },

    assignments: {
      type: Array as PropType<IAnalyticSubordinateUserGetResponseData['assignments']>,
      default: () => [] as IAnalyticSubordinateUserGetResponseData['assignments'],
    },

    progress: {
      type: Number as PropType<number>,
      default: 0,
    },

    progressDiff: {
      type: Number as PropType<number>,
      default: 0,
    },

    isMyOwnProfile: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    hasAssignments() {
      return this.assignments.length > 0;
    },
  },

  methods: {
    getCover(assignment: IAnalyticSubordinateUserAssignments) {
      return getTrackCover(this.getCoverId(assignment));
    },
    getCoverId(assignment: IAnalyticSubordinateUserAssignments) {
      return assignment.catalogueAtomMainId;
    },
  },
});
