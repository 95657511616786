
/**
 *  Пример использования
 *  <TeamProgress
 *    :average-percentage-pass="0.45"
 *    :average-percentage-pass-diff="0.12"
 *  />
 */
import { defineComponent, PropType } from 'vue';
import ProgressDiff from '@/components/ui/BaseCircleProgress/ProgressDiff.vue';
import HelpBtnDialog from '@/components/ui/HelpBtnDialog/HelpBtnDialog.vue';

export default defineComponent({
  name: 'TeamProgress',
  components: {
    ProgressDiff,
    HelpBtnDialog,
  },
  props: {
    averagePercentagePass: {
      type: Number as PropType<number>,
      default: 0,
      validator(v: number) {
        return v >= 0 && v <= 1;
      },
    },
    averagePercentagePassDiff: {
      type: Number as PropType<number>,
      default: 0,
      validator(v: number) {
        return v >= 0 && v <= 1;
      },
    },
  },
  computed: {
    circularValue(): number {
      return this.averagePercentagePass * 100;
    },
  },
});
