
import Vue, { PropType } from 'vue';
import { Level, LevelEnum, LevelsBox } from '@/domains/multileveltest';

export default Vue.extend({
  name: 'TestResultMultiLevelTestDetails',
  components: {

  },
  props: {
    level: {
      type: String as PropType<LevelEnum>,
      default: '',
    },
    maxLevel: {
      type: String as PropType<LevelEnum>,
      default: '',
    },
  },
  computed: {
    currentLevel(): Level {
      return LevelsBox.find(({ type }) => type === this.level) || LevelsBox[0] as Level;
    },
    currentMaxLevel(): Level {
      return LevelsBox.find(({ type }) => type === this.maxLevel) || LevelsBox[0] as Level;
    },
  },
  methods: {
  },
});
