
import Vue, { PropType } from 'vue';
import DialogComponent from '@/components/ui/DialogComponent';

export default Vue.extend({
  name: 'HelpBtnDialog',
  components: {
    DialogComponent,
  },
  props: {
    title: {
      type: String as PropType<string>,
      default: '',
    },
    dataTestLabel: {
      type: String as PropType<string>,
      default: '',
    },
    dataTestModal: {
      type: String as PropType<string>,
      default: '',
    },
  },
});
