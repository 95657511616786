
import { defineComponent } from 'vue';
import { RawLocation, RouterLink } from 'vue-router';
import { useI18n } from '@/plugins/vue-i18n';
import { Names } from '@/plugins/vue-router';
import CupOfCoffeeImage from '@/assets/img/cup-of-coffee.png';

export default defineComponent({
  name: 'EmptyAssignmentsListWidget',
  components: { RouterLink },
  setup() {
    const { t } = useI18n();

    const catalogueRoute: RawLocation = {
      name: Names.R_APP_LEARNING_CATALOGUE,
    };

    return {
      t,
      catalogueRoute,
      CupOfCoffeeImage,
    };
  },
});
