
import { defineComponent, PropType } from 'vue';
import { RawLocation } from 'vue-router';
import TTPrompt from '@uikit/components/TTPrompt/TTPrompt.vue';
import AtomCoverPlaceholder from '@/components/ui/AtomCoverPlaceholder/AtomCoverPlaceholder.vue';
import { IAtom } from '@/domains/atom';
import { useImageLoader } from '@/composables';

export default defineComponent({
  name: 'DeadlineCard',

  components: {
    TTPrompt,
    AtomCoverPlaceholder,
  },

  inject: ['Names'],

  props: {
    assignmentUserId: {
      type: Number,
      required: true,
    },
    catalogueAtomMainId: {
      type: String,
      default: null,
    },
    deadline: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    imgSrc: {
      type: String,
      default: '',
    },
    atomType: {
      type: String as PropType<IAtom['atomName']>,
      required: true,
    },
    isMyOwnProfile: {
      type: Boolean,
      default: true,
    },
  },

  setup() {
    const {
      isImageLoading,
      onLoadStartHandler,
      onLoadErrorHandler,
    } = useImageLoader();

    return {
      isImageLoading,
      onLoadStartHandler,
      onLoadErrorHandler,
    };
  },

  computed: {
    deadlineHumanized(): string {
      const deadline = this.$dayjs(this.deadline);

      if (deadline.isValid()) {
        const resolver = [
          {
            value: deadline.isToday(),
            text: this.$t('DeadlineCard.today'),
          },
          {
            value: deadline.isTomorrow(),
            text: this.$t('DeadlineCard.tomorrow'),
          },
          {
            value: true,
            text: deadline.format('DD MMMM'),
          },
        ];
        return resolver.find((i) => i.value)!.text;
      }

      return '';
    },
    isExpires(): boolean {
      const today = this.$dayjs();
      const deadline = this.$dayjs(this.deadline);
      return deadline.diff(today, 'week') < 1;
    },
    markedColor(): { date: string, line: string } {
      return this.isExpires
        ? { date: 'tt-dark-red--text', line: 'tt-dark-red' }
        : { date: 'tt-dark-blue--text', line: 'tt-dark-blue' };
    },
    to(): RawLocation {
      if (!this.isMyOwnProfile) {
        return '';
      }

      return {
        name: this.Names.R_APP_LEARNING_ASSIGNMENT_RUN,
        params: {
          assignmentId: this.assignmentUserId.toString(),
          catalogueAtomMainId: this.catalogueAtomMainId,
        },
      };
    },
  },
});
