
import { RawLocation } from 'vue-router';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'TestResultRow',

  props: {
    testName: {
      type: String as PropType<string>,
      default: '',
    },
    trackName: {
      type: String as PropType<string>,
      default: '',
    },
    trackSessionId: {
      type: String as PropType<String>,
      default: '',
    },
    to: {
      type: Object as PropType<RawLocation>,
      required: true,
    },
  },
});
