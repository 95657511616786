
import { RawLocation } from 'vue-router';
import { createNamespacedHelpers } from 'vuex';
import { Component, defineComponent, PropType } from 'vue';
import { IAnalyticSubordinateUserBaseTestResult } from '@/domains/assignment';
import { AtomType } from '@/domains/atom';
import TestResultRow from './TestResultRow.vue';
import TestResultQuizTest from './TestResultQuizTest.vue';
import TestResultMultiLevelTest from './TestResultMultiLevelTest.vue';
import { isQuizType } from '@/domains/quiz';
import { isMultileveltestType } from '@/domains/multileveltest/utils';
import { IAnalyticSubordinateUserTestsResults } from '@/services/api/lxp-assignment/types';

const userHelpers = createNamespacedHelpers('user');

const LIMIT = 5;

export default defineComponent({
  name: 'TestResultWrapper',

  components: {
    TestResultRow,
  },
  inject: ['Names'],

  props: {
    results: {
      type: Array as PropType<IAnalyticSubordinateUserTestsResults[]>,
      default: () => [],
    },
  },

  data() {
    return {
      page: 1,
    };
  },

  computed: {
    ...userHelpers.mapGetters({ currentUserId: 'userId' }),

    userId(): string {
      return this.$route.params.employeeId ?? this.currentUserId;
    },

    shownResultItems(): IAnalyticSubordinateUserTestsResults[] {
      return this.results.slice(0, this.page * LIMIT);
    },

    localeParams(): { count: number; total: number } {
      const diff = this.results.length - this.shownResultItems.length;

      return {
        count: diff > LIMIT ? LIMIT : diff,
        total: diff,
      };
    },

    showMoreResultsButton(): boolean {
      return this.shownResultItems.length !== this.results.length;
    },

    isProfilePage(): boolean {
      return this.$route.name === this.Names.R_APP_PROFILE;
    },
  },
  methods: {
    to({
      assignmentSessionId,
      trackSessionId,
      playerSessionId,
      stepId,
      testResultType,
    }: Pick<
      IAnalyticSubordinateUserBaseTestResult,
      'assignmentSessionId' | 'trackSessionId' | 'playerSessionId' | 'stepId'
    > & Pick<IAnalyticSubordinateUserTestsResults, 'testResultType'>): RawLocation {
      return {
        name: this.getRouteName(testResultType),
        params: {
          userId: this.userId,
          assignmentId: String(assignmentSessionId),
          trackSessionId: String(trackSessionId),
          playerSessionId: String(playerSessionId),
          stepId: stepId ?? '',
          atomType: testResultType,
        },
      };
    },

    getRouteName(testResultType: IAnalyticSubordinateUserTestsResults['testResultType']) {
      // NOTE: просмотр своих результатов
      if (this.isProfilePage) {
        if (isMultileveltestType(testResultType)) {
          return this.Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_MULTILEVELTEST_RESULT;
        }

        if (isQuizType(testResultType)) {
          return this.Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_QUIZ_RESULT;
        }
      }

      // NOTE: просмотр результатов сотрудника
      if (isMultileveltestType(testResultType)) {
        return this.Names.R_APP_MANAGER_ANALYTICS_EMPLOYEE_RESULT_MULTILEVELTEST;
      }

      if (isQuizType(testResultType)) {
        return this.Names.R_APP_MANAGER_ANALYTICS_EMPLOYEE_RESULT_QUIZ;
      }

      return this.Names.R_ERROR_404;
    },

    getTestResultComponent({
      testResultType,
    }: {
      testResultType: IAnalyticSubordinateUserTestsResults['testResultType'];
    }): Component | null {
      if (isQuizType(testResultType)) {
        return TestResultQuizTest;
      }

      if (isMultileveltestType(testResultType)) {
        return TestResultMultiLevelTest;
      }

      return null;
    },

    getTestResultDetails(result: IAnalyticSubordinateUserTestsResults) {
      if (result.testResultType === AtomType.QUIZ) {
        return {
          ...result.quizTestResult,
          atomContentId: result.atomContentId,
          assignmentUserId: result.assignmentUserId,
        };
      }

      if (result.testResultType === AtomType.MULTILEVELTEST) {
        return {
          ...result.multiTestResult,
          atomContentId: result.atomContentId,
          assignmentUserId: result.assignmentUserId,
        };
      }

      return null;
    },
  },
});
