
import { computed, defineComponent, PropType } from 'vue';
import { UUID } from '@/domains/common';
import { useI18n } from '@/plugins/vue-i18n';

export default defineComponent({
  name: 'TestResultQuizTestDetails',

  components: {

  },

  props: {
    atomContentId: {
      type: String as PropType<UUID>,
      required: true,
    },
    testPassed: {
      type: Boolean,
      required: true,
    },
    passedQuestions: {
      type: Number,
      required: true,
    },
    totalQuestions: {
      type: Number,
      required: true,
    },
    userThreshold: {
      type: Number,
      required: true,
    },
    testThreshold: {
      type: Number,
      required: true,
    },
    assignmentUserId: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { t, tc } = useI18n();

    const title = computed(() => (props.testPassed
      ? t('TestResultQuizTestDetails.passed')
      : t('TestResultQuizTestDetails.failed')));

    const description = computed(() => tc(
      'TestResultQuizTestDetails.description',
      props.totalQuestions,
      {
        passed: props.passedQuestions,
      },
    ));

    return {
      title,
      description,
    };
  },
});
