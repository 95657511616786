
import Vue, { PropType } from 'vue';
import { IAnalyticsEmployee } from '@/domains/employee';

export default Vue.extend({
  name: 'AnalyticsEmployeesCard',
  inject: ['Names'],
  props: {
    employee: {
      type: Object as PropType<IAnalyticsEmployee>,
      required: true,
    },
    deadline: {
      type: Number,
      default: 0,
    },
    tracksCount: {
      type: Number as PropType<number>,
      default: 0,
    },
    email: {
      type: String as PropType<string>,
      default: '',
    },
  },
  computed: {

    getFullName(): string {
      return `${this.employee.lastName} ${this.employee.firstName}`;
    },
    mailTo(): string {
      return `mailto:${this.email}
?subject=${this.$t('AnalyticsEmployeesCard.mail.subject')}
&body=${this.employee.firstName}, ${this.$t('AnalyticsEmployeesCard.mail.hello')}
%0D%0D${this.$t('AnalyticsEmployeesCard.mail.text.begin')}
${this.$tc('pluralized.estimateToProgram', this.tracksCount)}.
${this.$t('AnalyticsEmployeesCard.mail.text.end')}
      `;
    },
    getDeadlineString(): string {
      const deadline = new Date(this.deadline).getTime();
      const now = new Date().getTime();

      const daysCount = (deadline - now) / 1000 / 60 / 60 / 24;

      const numberDeadline = Math.floor(Math.max(daysCount));

      if (numberDeadline > 0) {
        return this.$t('AnalyticsEmployeesCard.days', { days: this.$tc('pluralized.days', numberDeadline) });
      }
      if (numberDeadline === 0) {
        return this.$t('AnalyticsEmployeesCard.dayNow');
      }
      if (numberDeadline < 0) {
        return this.$t('AnalyticsEmployeesCard.dayLost');
      }
      return '';
    },
  },
  methods: {
    goToEmployeePage() {
      this.$router.push({
        name: this.Names.R_APP_MANAGER_ANALYTICS_EMPLOYEE_VIEW,
        params: {
          employeeId: this.employee?.id ?? '',
        },
      });
    },
  },
});
