
import Vue from 'vue';
import TTChip from '@uikit/components/TTChip/TTChip.vue';
import { toNumber } from '@/helpers';
import EmployeesProgressSidebar from '@/components/analytics/EmployeesProgressSidebar/EmployeesProgressSidebar.vue';
import HelpBtnDialog from '@/components/ui/HelpBtnDialog/HelpBtnDialog.vue';
import EmployeeProgressCard from '@/components/analytics/EmployeeProgressCard/EmployeeProgressCard.vue';

import * as LxpAssignmentService from '@/services/api/lxp-assignment';

import { IEmployeeNotification } from '@/domains/employee';
import { ISubordinatesUser, ITeamProgress, SubordinateStatus } from '@/domains/assignment';

interface IData {
  statuses: Record<SubordinateStatus, {
    text: string;
    icon: string;
    color: string;
    count: number;
    isActive: boolean;
    dataTestLabel: string;
  }>;
  users: ISubordinatesUser[];
  teamProgress: ITeamProgress;
  employeeNotifications: IEmployeeNotification[];
  subordinatesCount: number;
}

export default Vue.extend({
  name: 'AnalyticsEmployees',
  components: {
    EmployeesProgressSidebar,
    HelpBtnDialog,
    TTChip,
    EmployeeProgressCard,
  },
  inject: ['Names'],
  data(): IData {
    return {
      statuses: {
        [SubordinateStatus.PENDING]: {
          text: this.$t('AnalyticsEmployees.status.pending'),
          icon: '$fireRegular',
          color: 'tt-light-orange',
          count: 0,
          isActive: false,
          dataTestLabel: 'not-start',
        },
        [SubordinateStatus.IN_PROCESS]: {
          text: this.$t('AnalyticsEmployees.status.inProgress'),
          icon: '$chevronCircleRight',
          color: 'tt-light-blue vibrant',
          count: 0,
          isActive: false,
          dataTestLabel: 'in-process',
        },
        [SubordinateStatus.COMPLETED]: {
          text: this.$t('AnalyticsEmployees.status.completed'),
          icon: '$checkCircle',
          color: 'tt-light-green vibrant',
          count: 0,
          isActive: false,
          dataTestLabel: 'completed',
        },
        [SubordinateStatus.NO_ASSIGNMENTS]: {
          text: this.$t('AnalyticsEmployees.status.noAssignments'),
          icon: '$graduationCap',
          color: 'tt-light-mono-24',
          count: 0,
          isActive: false,
          dataTestLabel: 'no-assignment',
        },
      },
      users: [] as ISubordinatesUser[],
      teamProgress: {} as ITeamProgress,
      employeeNotifications: [] as IEmployeeNotification[],
      subordinatesCount: 0,
    };
  },
  computed: {
    requestStatues(): SubordinateStatus[] {
      return Object.entries(this.statuses)
        .filter(([_, val]) => val.isActive)
        .map(([key, _]) => key as SubordinateStatus);
    },

    subordinateStatusNames() {
      return Object.values(SubordinateStatus);
    },
  },
  watch: {
    requestStatues(val: SubordinateStatus[]) {
      this.fetch(val);
    },
  },
  async created() {
    await this.fetch(this.requestStatues);
  },
  methods: {
    async fetch(statuses: SubordinateStatus[] = []) {
      try {
        const response = await LxpAssignmentService.analyticSubordinatesGet({ statuses });
        this.users = response.subordinatesUsers;

        this.teamProgress = {
          progress: this.numberToPercent(response.averageUsersProgress),
          progressDiff: this.numberToPercent(response.usersProgressChangePerWeek),
        };

        this.subordinatesCount = response.subordinatesCount;

        this.employeeNotifications = response.usersWithHotDeadline
          .map((user) => ({
            employee: {
              id: user.userId,
              firstName: user.userFirstName,
              lastName: user.userLastName,
            },
            deadline: toNumber(user.hotTracksDeadline),
            email: user.userEmail ?? '',
            tracksCount: Number(user.hotTracksCount),
          }))
          .sort((firstUser, secondUser) => (firstUser.deadline > secondUser.deadline ? 1 : -1));

        Object.values(response.statusesCount).forEach((status) => {
          this.statuses[status.statusName].count = status.count;
        });
      } catch (e) {
        this.$di.notify.error({ content: this.$t('AnalyticsEmployees.error') });
      }
    },
    numberToPercent(val: number): number {
      return val ? Number((val / 100)) : 0;
    },
    clickChip(status: SubordinateStatus) {
      this.statuses[status].isActive = !this.statuses[status].isActive;
    },
  },

});
