
import Vue, { PropType } from 'vue';
import { createNamespacedHelpers } from 'vuex';
import PlanEducationItem from './PlanEducationItem.vue';
import { IAnalyticSubordinateUserAssignments } from '@/domains/assignment';
import { getTrackCover } from '@/domains/track';

const userHelpers = createNamespacedHelpers('user');

const LIMIT = 5;

export default Vue.extend({
  name: 'ProfilePlanEducation',

  components: {
    PlanEducationItem,
  },

  inject: ['Names'],

  props: {
    assignments: {
      type: Array as PropType<IAnalyticSubordinateUserAssignments[]>,
      required: true,
    },

    isChief: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      page: 1,
    };
  },

  computed: {
    ...userHelpers.mapGetters({ currentUserId: 'userId' }),

    shownAssignmentsItems(): IAnalyticSubordinateUserAssignments[] {
      return this.assignments ? this.assignments.slice(0, this.page * LIMIT) : [];
    },

    localeParams(): {count: number, total: number} {
      const diff = this.assignments.length - this.shownAssignmentsItems.length;
      return {
        count: diff > LIMIT ? LIMIT : diff,
        total: diff,
      };
    },
  },
  methods: {
    courseCover(assignments: IAnalyticSubordinateUserAssignments) {
      return getTrackCover(this.getCoverId(assignments), { size: '80x80' });
    },

    getCoverId(assignments: IAnalyticSubordinateUserAssignments) {
      return assignments.catalogueAtomMainId;
    },

    to({
      assignmentUserId,
      catalogueAtomMainId,
    }: IAnalyticSubordinateUserAssignments) {
      return {
        name: this.Names.R_APP_LEARNING_ASSIGNMENT_RUN,
        params: {
          assignmentId: assignmentUserId,
          catalogueAtomMainId,
        },
      };
    },
  },
});
