
import Vue, { PropType } from 'vue';
import AnalyticsEmployeesCard from '@/components/analytics/AnalyticsEmployeesCard.vue';
import HelpBtnDialog from '@/components/ui/HelpBtnDialog/HelpBtnDialog.vue';
import { IEmployeeNotification } from '@/domains/employee';

export default Vue.extend({
  name: 'ExpiringEmployeeStudyNotifications',
  components: {
    AnalyticsEmployeesCard,
    HelpBtnDialog,
  },
  props: {
    value: {
      type: Array as PropType<IEmployeeNotification[]>,
      default: () => [] as IEmployeeNotification[],
    },
  },
  methods: {
    remove(id: string) {
      this.$emit('input', this.value.filter((i) => i.employee.id !== id));
    },
  },
});
