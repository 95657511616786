
import { defineComponent, PropType } from 'vue';
import { RawLocation } from 'vue-router';

import TestResultRow from './TestResultRow.vue';
import TestResultQuizTestDetails from './TestResultQuizTestDetails.vue';
import { IQuizTestResult } from '@/domains/assignment';

export default defineComponent({
  name: 'TestResultQuizTest',

  components: {
    TestResultRow,
    TestResultQuizTestDetails,
  },

  props: {
    testName: {
      type: String,
      required: true,
    },
    trackName: {
      type: String,
      required: true,
    },
    to: {
      type: Object as PropType<RawLocation>,
      required: true,
    },
    details: {
      type: Object as PropType<IQuizTestResult>,
      required: true,
    },
  },
});
