<template>
  <div class="d-flex align-center">
    <TTAvatar
      :src="avatarUrl"
      :text="avatarText"
      class="mr-4"
    />
    <div>
      <div
        class="tt-text-body-2"
        data-test="user-detail-view-fullname"
      >
        {{ fullname }}
      </div>
      <div
        v-if="post"
        class="tt-text-body-2 tt-light-mono-46--text"
        data-test="user-detail-view-post"
      >
        {{ post }}
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import TTAvatar from '@uikit/components/TTAvatar/TTAvatar.vue';

export default Vue.extend({
  name: 'UserDetailView',
  components: { TTAvatar },
  props: {
    fullname: {
      type: String,
      required: true,
    },
    avatarText: {
      type: String,
      required: false,
      default: '',
    },
    post: {
      type: String,
      required: false,
      default: '',
    },
    avatarUrl: {
      type: String,
      required: false,
      default: '',
    },
  },
});

</script>
