
import Vue, { PropType } from 'vue';
import { DateTimeISO8601 } from '@/domains/common';

interface IInfo {
  value: boolean;
  color: {
    background: string;
    text: string;
  },
  text: string;
}

// NOTE: Почти полная копия AssignedTracksCardDeadline
export default Vue.extend({
  name: 'ProfileDeadlineChip',
  props: {
    finishDate: {
      type: String as PropType<DateTimeISO8601>,
      default: '',
    },

    progress: {
      type: Number as PropType<number>,
      default: 0,
    },
  },
  computed: {
    deadlineHumanized(): string {
      const deadline = this.$dayjs(this.finishDate);

      if (deadline.isToday()) {
        return this.$t('ProfileDeadlineChip.completeToday');
      }
      if (deadline.isTomorrow()) {
        return this.$t('ProfileDeadlineChip.completeTomorrow');
      }
      return this.$t('ProfileDeadlineChip.completeBefore', { date: deadline.format('DD MMMM') });
    },

    isExpires(): boolean {
      const today = this.$dayjs();
      const deadline = this.$dayjs(this.finishDate);
      return deadline.diff(today, 'week') < 1;
    },

    isCompleted(): boolean {
      return this.progress >= 100;
    },

    isFailed(): boolean {
      const today = this.$dayjs();
      const deadline = this.$dayjs(this.finishDate);
      return !this.isCompleted && today.isAfter(deadline, 'days');
    },

    info(): IInfo {
      const resolver: IInfo[] = [
        {
          value: this.isFailed,
          color: {
            background: 'tt-light-red pale',
            text: 'tt-light-red--text',
          },
          text: this.$t('ProfileDeadlineChip.failed'),
        },
        {
          value: this.isCompleted,
          color: {
            background: 'tt-light-mono-4',
            text: 'tt-light-mono-46--text',
          },
          text: this.$t('ProfileDeadlineChip.completed'),
        },
        {
          value: this.isExpires,
          color: {
            background: 'tt-light-red pale',
            text: 'tt-light-red--text',
          },
          text: this.deadlineHumanized,
        },
        {
          value: true,
          color: {
            background: 'tt-light-mono-4',
            text: 'tt-light-mono-46--text',
          },
          text: this.deadlineHumanized,
        },
      ];

      return resolver.find((i) => i.value) as IInfo;
    },
  },
});
