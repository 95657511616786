
import { defineComponent, PropType } from 'vue';
import { RawLocation } from 'vue-router';

import TestResultRow from './TestResultRow.vue';
import TestResultMultiLevelTestDetails from './TestResultMultiLevelTestDetails.vue';
import { IAnalyticSubordinateUserMultilevelTestResult } from '@/domains/assignment';

export default defineComponent({
  name: 'TestResultMultiLevelTest',

  components: {
    TestResultRow,
    TestResultMultiLevelTestDetails,
  },

  props: {
    testName: {
      type: String,
      required: true,
    },
    trackName: {
      type: String,
      required: true,
    },
    to: {
      type: Object as PropType<RawLocation>,
      required: true,
    },
    details: {
      type: Object as PropType<Pick<IAnalyticSubordinateUserMultilevelTestResult, 'name' | 'level' | 'maxLevel'>>,
      required: true,
    },
  },
});
