
import Vue, { PropType } from 'vue';
import { AssignmentStatus } from '@/domains/assignment';
import { AtomType } from '@/domains/atom';

export interface IFilter {
  icon: string;
  text: string;
  value: string | string[];
  dataTestLabel: string;
  count: number;
  iconColor?: string;
}

export interface AssignmentFilter extends IFilter {
  value: AssignmentStatus | AssignmentStatus[];
}

export interface AtomTypeFilter extends IFilter {
  value: AtomType | AtomType[];
}

export default Vue.extend({
  name: 'ProfileAssignmentFilters',

  props: {
    value: {
      type: Array as PropType<(AtomType | AssignmentStatus)[]>,
      default: () => [] as (AtomType | AssignmentStatus)[],
    },

    filters: {
      type: Array as PropType<(AssignmentFilter | AtomTypeFilter)[]>,
      default: () => [] as (AssignmentFilter | AtomTypeFilter)[],
    },
  },

  methods: {
    onChangeHandler(v: AtomType[]) {
      this.$emit('input', v.flat());
    },
  },
});
