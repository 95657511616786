
import Vue, { PropType } from 'vue';
import ProgressChangeIndicator from '@/components/ui/ProgressChangeIndicator/ProgressChangeIndicator.vue';

export default Vue.extend({
  name: 'AssignedLearningProgressCard',
  components: {
    ProgressChangeIndicator,
  },
  props: {
    progress: {
      type: Number as PropType<number>,
      default: 0,
    },
    progressDiff: {
      type: Number as PropType<number>,
      default: 0,
    },
  },
  computed: {
    roundedProgress(): string {
      return `${Math.floor(this.progress)}`;
    },
  },
});
