<template>
  <div class="d-flex flex-column align-center">
    <p
      class="tt-text-headline-2 tt-light-mono-100--text mb-0"
      data-test="total-users-progress"
    >
      {{ Math.trunc(currentProgressPercent) }}%
    </p>
    <div
      v-if="isDifferenceVisible"
      class="d-flex align-center"
    >
      <VIcon
        v-if="isGotBetter"
        size="14"
        class="tt-light-green--text text--vibrant mr-2"
      >
        $arrowCircleUp
      </VIcon>
      <VIcon
        v-else
        size="14"
        class="tt-light-orange--text mr-2"
      >
        $arrowCircleDown
      </VIcon>
      <p class="tt-text-body-2 tt-light-mono-64--text mb-0">
        <span v-if="isGotBetter">+</span>
        <span data-test="users-progress-per-week">{{ Math.trunc(progressDifference) }}%</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressDiff',
  props: {
    currentProgressPercent: {
      type: Number,
      default: 0,
      required: true,
    },
    previousProgressPercent: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  computed: {
    progressDifference() {
      return this.currentProgressPercent - this.previousProgressPercent;
    },
    isGotBetter() {
      return this.progressDifference > 0;
    },
    isDifferenceVisible() {
      return this.progressDifference && this.previousProgressPercent !== 0;
    },
  },
};
</script>
